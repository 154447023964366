























import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'

export default Vue.extend({
  name: 'Select',
  props: {
    options: {
      type: Array,
      required: true
    },
    isDashboard: {
      default: false,
      type: Boolean
    }
  },
  components: { VSelect },
  data () {
    return {
      open: false
    }
  },
  methods: {
    ...mapActions('adsManager', ['_setFilterDashboard', '_setFilter']),
    selectOption (option) {
      this.setFilter(
        option.label
          .toLowerCase()
          .split(' ')
          .join('')
      )
    },

    away () {
      this.open = false
    },

    checked (getFilter, option) {
      return getFilter[
        option
          .toLowerCase()
          .split(' ')
          .join('')
      ]
    }
  },

  computed: {
    ...mapGetters('adsManager', {
      _getFilter: 'getFilter',
      _getFilterDashboard: 'getFilterDashboard'
    }),
    getFilter () {
      return this.isDashboard ? this._getFilterDashboard : this._getFilter
    },
    setFilter () {
      return this.isDashboard ? this._setFilterDashboard : this._setFilter
    },
    optionsWithFilters () {
      return this.options.map((option) => {
        return {
          label: option,
          filter: this.getFilter[option
            .toLowerCase()
            .split(' ')
            .join('')]
        }
      })
    }
  }
})
