

























































































































































































































import Vue from 'vue'
import VSelect from '@/components/BaseComponents/VSelect/VSelect.vue'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { IFilterEntry } from '@/utils/Filter'
import SaveFilterModal from '@/components/ADSManager/MainComponent/SaveFilterModal.vue'

export default Vue.extend({
  name: 'AdsManagerFilter',
  components: {
    VSelect,
    SaveFilterModal
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  data: () => ({
    edit: false,
    filter: null,
    value: '',
    predicate: null,
    statuses: []
  }),
  computed: {
    ...mapGetters('filter', ['getFiltersMeta', 'getSavedFilters', 'getFilters']),
    getFiltersList () {
      return this.getFiltersMeta?.filters?.list || []
    },
    getFiltersListNotAssigned () {
      return this.getFiltersList
        .filter((filter) => (!this.getEntries
          .find((entry) => entry.field === filter.key))) || []
    },
    getFiltersListNames (): { [key: string]: string } {
      return this.getFiltersMeta?.filters?.list.reduce((prev: { [key: string]: string }, curr) => {
        prev[curr.key] = curr.name
        return prev
      }, {}) || {}
    },
    getFiltersListTypes (): { [key: string]: string } {
      return this.getFiltersMeta?.filters?.list.reduce((prev: { [key: string]: string }, curr) => {
        prev[curr.key] = curr.type
        return prev
      }, {}) || {}
    },
    getStatuses () {
      return this.getFiltersMeta?.filters?.select?.statuses || {}
    },
    getForMetrics (): { [key in 'key' | 'name' | 'value']: string }[] {
      return this.getFiltersMeta?.filters?.select?.for_metrics || []
    },
    getEntries () {
      return this.getFilters?.entries || []
    },
    localStorageKey (): string {
      return `filtersType${this.type[0].toUpperCase()}${this.type.substr(1)}`
    }
  },
  methods: {
    ...mapMutations('filter', ['setFilters', 'addFilter']),
    ...mapActions('filter', ['loadFilters', 'saveFilter', 'deleteFilter']),
    ...mapActions('notifications', ['addNotification']),
    setFromSavedFilter (savedFilter) {
      if (savedFilter?.attributes?.user_filter_data?.length) {
        this.setFilters(savedFilter.attributes.user_filter_data.map((filter): IFilterEntry => ({
          field: filter.field,
          predicate: filter.predicate,
          value: filter.value
        })))
        this.sendFilter()
        this.cancelEdit()
      }
    },
    setFilter (filter): void {
      this.resetFilter()
      this.filter = filter
    },
    cancelEdit (): void {
      this.edit = false
      this.resetFilter()
      this.filter = null
    },
    resetFilter (): void {
      this.statuses = []
      this.value = ''
      this.predicate = null
    },
    applyFilter (): void {
      this.addFilter({
        field: this.filter.key,
        value: this.statuses.length
          ? this.statuses.join()
          : this.value,
        predicate: this.predicate?.key || null
      })
      this.sendFilter()
      this.cancelEdit()
    },
    removeFilter (entry: IFilterEntry): void {
      this.getFilters.remove(entry)
      this.sendFilter()
    },
    sendFilter (): void {
      localStorage.setItem(this.localStorageKey, JSON.stringify(this.getEntries))
      this.$emit('applyFilters')
    },
    toggleStatus (option: string): void {
      if (this.statuses.includes(option)) {
        this.statuses = this.statuses.filter(status => status !== option)
      } else {
        this.statuses.push(option)
      }
    },
    saveFilters (name: string, close: () => void, error: () => void): void {
      this.saveFilter({
        name: name,
        type: this.type,
        filters: this.getEntries
      }).then(close)
        .catch(error)
    }
  },
  mounted () {
    const localFilters = JSON.parse(localStorage.getItem(this.localStorageKey))

    if (localFilters) {
      this.setFilters(localFilters)
    }

    this.loadFilters(this.type)
  }
})
