




































































import Vue from 'vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import moment from 'moment-timezone'
export default Vue.extend({
  name: 'BaseDatePicker',

  components: {
    DatePicker
  },

  props: {
    localStorageName: String,
    timezone: String
  },

  data () {
    return {
      dateRange: JSON.parse(localStorage.getItem(this.localStorageName)) || [
        moment()
          .subtract(7, 'd')
          .format('DD-MM-YYYY'),
        moment().format('DD-MM-YYYY')
      ],
      DatePickerIsOpen: false
    }
  },

  methods: {
    allPeriod (emit) {
      this.dateRange[0] = ''
      this.dateRange[1] = ''
      emit(this.dateRange)
    },

    today (emit) {
      const today = new Date(moment().format('YYYY-MM-DD'))
      this.dateRange[0] = today
      this.dateRange[1] = today
      emit(this.dateRange)
    },

    yesterday (emit) {
      const today = new Date(moment().format('YYYY-MM-DD'))
      const yesterday = new Date(
        moment()
          .subtract(1, 'days')
          .format('YYYY-MM-DD')
      )
      this.dateRange[0] = yesterday
      this.dateRange[1] = today
      emit(this.dateRange)
    },

    last7day (emit) {
      const today = new Date(moment().format('YYYY-MM-DD'))
      const last7Day = new Date(
        moment()
          .subtract(7, 'd')
          .format('YYYY-MM-DD')
      )
      this.dateRange[0] = last7Day
      this.dateRange[1] = today
      emit(this.dateRange)
    },

    last14day (emit) {
      const today = new Date(moment().format('YYYY-MM-DD'))
      const last14Day = new Date(
        moment()
          .subtract(14, 'd')
          .format('YYYY-MM-DD')
      )
      this.dateRange[0] = last14Day
      this.dateRange[1] = today
      emit(this.dateRange)
    },

    thisMonth (emit) {
      const today = new Date(moment().format('YYYY-MM-DD'))
      const thisMonth = new Date(
        moment()
          .startOf('month')
          .format('YYYY-MM-DD')
      )
      this.dateRange[0] = thisMonth
      this.dateRange[1] = today
      emit(this.dateRange)
    },

    lastMonth (emit) {
      const lastMonthFirstDay = new Date(
        moment()
          .subtract(1, 'months')
          .startOf('month')
          .format('YYYY-MM-DD')
      )
      const lastMonthLastDay = new Date(
        moment()
          .subtract(1, 'months')
          .endOf('month')
          .format('YYYY-MM-DD')
      )
      this.dateRange[0] = lastMonthFirstDay
      this.dateRange[1] = lastMonthLastDay
      emit(this.dateRange)
    },

    emitDateRange () {
      if (this.dateRange[0] === null || this.dateRange[1] === null) {
        this.dateRange[0] = ''
        this.dateRange[1] = ''
      }
      this.$emit('periodChange', this.dateRange)
    }
  }
})
