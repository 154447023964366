



























import Vue from 'vue'
import Modal from '@/components/Modal.vue'

export default Vue.extend({
  name: 'SaveFilterModal',
  components: {
    Modal
  },
  data: () => ({
    value: '',
    sending: false
  }),
  methods: {
    close () {
      this.$modal.hide(this.$options.name)
      this.value = ''
      this.unlock()
    },
    unlock () {
      this.sending = false
    }
  }
})
